import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { RsFile } from '@lib/rs-ngx';
import { Observable } from 'rxjs';
import { Product } from '../../models/product';

@Injectable()
export class ProductService {

  constructor(
    private readonly http: HttpClient
  ) {
  }

  public getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(
      `${environment.apiUrl}/product`
    );
  }

  public downloadProductTermsConditions(productId: number): Observable<HttpResponse<Blob>> {
    return this.http.get<HttpResponse<Blob>>(`${environment.apiUrl}/product/${productId}/terms-and-conditions`, {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    });
  }

  public downloadProductLogo(productId: number): Observable<HttpResponse<Blob>> {
    return this.http.get<HttpResponse<Blob>>(`${environment.apiUrl}/product/${productId}/logo`, {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    });
  }

  public createProduct(
    body: Product,
    documentFile: RsFile,
    logoFile: RsFile
  ): Observable<void> {

    const formData: FormData = new FormData();

    formData.append('logo', logoFile.data, logoFile.data.name);
    formData.append('terms-and-conditions', documentFile.data, documentFile.data.name);
    formData.append(
      'data',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );

    return this.http.post<void>(
      `${environment.apiUrl}/product`,
      formData
    );
  }

  public updateProduct(productId: number, body: Product, logoFile: RsFile): Observable<void> {
    const formData: FormData = new FormData();

    if (logoFile) {
      formData.append('logo', logoFile.data, logoFile.data.name);
    }
    formData.append(
      'data',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );

    return this.http.post<void>(
      `${environment.apiUrl}/product/${productId}`,
      formData
    );
  }

  public generateProductDeliveryReceipt(body: Product, logoFile: RsFile): Observable<HttpResponse<Blob>> {
    const formData: FormData = new FormData();

    const data = {
      id: body.id,
      defaultProduct: body.defaultProduct,
      logoName: body.logoName,
      referenceCode: body.referenceCode
    };

    formData.append('logo', logoFile.data, logoFile.data.name);
    formData.append(
      'data',
      new Blob([JSON.stringify(data)], { type: 'application/json' })
    );

    return this.http.post<HttpResponse<Blob>>(
      `${environment.apiUrl}/product/delivery-receipt-mockup`,
      formData,
      {
        observe: 'response' as 'body',
        responseType: 'blob' as 'json'
      }
    );
  }

}
