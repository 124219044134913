import { Injectable } from '@angular/core';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  CompleteRegistrationResult,
  DefaultDeliveryInformation,
  InsuranceBrokersRecognizedByInsuranceCompany,
  InsuranceCompaniesAndMandateStatus,
  Registration,
  RegistrationDraft
} from '@app/registrations/shared/models/registration';
import { RsMessagesHandlerService, UUID } from '@lib/rs-ngx';
import { map, shareReplay, tap } from 'rxjs/operators';
import { RegistrationDivSimulation } from '@app/registrations/shared/models/registration-div-simulation';
import { LeasingCompanyDeliveryAddresses } from '@shared/models/leasing-company-delivery-addresses';

@Injectable({
  providedIn: 'root'
})
export class RegistrationDetailsOverviewService {

  constructor(
    private readonly http: HttpClient,
    private readonly rsToaster: RsMessagesHandlerService
  ) {
  }
  public readonly getInsuranceCompaniesAndMandateStatus$ =
    this.http.get<InsuranceCompaniesAndMandateStatus[]>(`${environment.apiUrl}/insurance-company/mandate-status`)
      .pipe(
        tap({ error: ({ httpError }) => this.rsToaster.showBeErrorMsg({ httpError }) }),
        shareReplay({ refCount: true, bufferSize: 1 })
      );

  public readonly getMandatedInsuranceCompanies$ = this.getInsuranceCompaniesAndMandateStatus$.pipe(
    map((insuranceCompanies) => insuranceCompanies.filter((company) => company.mandated)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public readonly getNonMandatedInsuranceCompanies$ =
    this.getInsuranceCompaniesAndMandateStatus$.pipe(
      map((insuranceCompanies) => insuranceCompanies.filter((company) => !company.mandated)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  public readonly getDefaultDeliveryInformation$ =
    this.http.get<LeasingCompanyDeliveryAddresses>(`${environment.apiUrl}/leasing-company/default-delivery-information`)
      .pipe(
        tap({ error: ({ httpError }) => this.rsToaster.showBeErrorMsg({ httpError }) })
      );

  public readonly getInsuranceBrokerName = (
    insuranceCompanyFsmaNumber: string | null,
    insuranceBrokerFsmaNumber: string | null
  ): Observable<string | null> =>  {
    const
      shouldMakeRequest = insuranceCompanyFsmaNumber && insuranceBrokerFsmaNumber,
      request = this.http
        .get<InsuranceCompaniesAndMandateStatus[]>(`${environment.apiUrl}/insurance-company/${insuranceCompanyFsmaNumber}/recognized-broker`)
        .pipe(
          map(res => res.find(broker => broker.fsmaNumber === insuranceBrokerFsmaNumber)?.name || null)
        );

    return shouldMakeRequest ? request : of(null);
  }

  public readonly getInsuranceCompanyName = (
    insuranceCompanyFsmaNumber: string | null
  ): Observable<string | null> =>  {
    return this.getInsuranceCompaniesAndMandateStatus$
      .pipe(
        map((insurances) => insurances.find(insurance => insurance.fsmaNumber === insuranceCompanyFsmaNumber)!.name)
      );
  }

  public readonly getDefaultDeliveryInformation = (): Observable<DefaultDeliveryInformation> => {
    return this.http.get<DefaultDeliveryInformation>(`${environment.apiUrl}/leasing-company/default-delivery-information`);
  }

  public readonly createOrUpdateDraft = (draft: RegistrationDraft): Observable<string> => {
    return this.http.put<string>(`${environment.apiUrl}/registration`, draft);
  }

  public readonly simulateRegistration = (id: UUID): Observable<RegistrationDivSimulation> => {
    return this.http.post<RegistrationDivSimulation>(`${environment.apiUrl}/registration/${id}/simulate`, {})
  }

  public readonly completeRegistration = (id: UUID): Observable<CompleteRegistrationResult> => {
    return this.http.post<CompleteRegistrationResult>(`${environment.apiUrl}/registration/${id}/complete`, {});
  }

  public readonly getInsuranceCompaniesAndMandateStatus = (): Observable<InsuranceCompaniesAndMandateStatus[]> => {
    return this.getInsuranceCompaniesAndMandateStatus$;
  }

  public readonly getInsuranceBrokersRecognizedByInsuranceCompany = (fsmaNumber: string): Observable<InsuranceBrokersRecognizedByInsuranceCompany[]> => {
    return this.http.get<InsuranceCompaniesAndMandateStatus[]>(`${environment.apiUrl}/insurance-company/${fsmaNumber}/recognized-broker`)
  }

  public readonly getDraftRegistrationDataForExistingRegistration = (orderId: UUID): Observable<Registration> => {
    return this.http.get<Registration>(
      `${environment.apiUrl}/registration/prefilled`,
      {
        params: {
          linkedOrderId: orderId
        }
      }
    )
  }

  public readonly getExistingRegistrationData = (id: UUID): Observable<Registration> => {
    return this.http.get<Registration>(`${environment.apiUrl}/registration/${id}`);
  }

  public readonly cancelRegistration = (id: UUID | null): Observable<string | null> => {
    return iif(
      () => id !== null,
      this.cancelRegistrationRequest(id!),
      of(null)
    );
  }

  public readonly cancelRegistrationRequest = (id: UUID): Observable<string> => {
    return this.http.post<string>(`${environment.apiUrl}/registration/${id}/cancel`, {})
  }

  /**
   * Returns a list of insurance brokers.
   * isInsuranceCompanyMandated flag allows to check whether the insurance brokers belongs to an insurance company mandated or not mandated.
   * @param fsmaNumber
   */
  public readonly getInsuranceBrokersAndIsMandatedCompany = (fsmaNumber: string): Observable<{
    brokers: InsuranceCompaniesAndMandateStatus[];
    isInsuranceCompanyMandated: boolean;
  }> => forkJoin({
    brokers: this.http.get<InsuranceCompaniesAndMandateStatus[]>(`${environment.apiUrl}/insurance-company/${fsmaNumber}/recognized-broker`),
    isInsuranceCompanyMandated: this.getMandatedInsuranceCompanies$
      .pipe(
        map((mandatedCompanies) => mandatedCompanies
          .find(company => company.fsmaNumber === fsmaNumber)?.mandated || false)
      )
  });

}
