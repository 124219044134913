<rs-top-bar-menu
  (onLogout)='onLogout()'
  (onRouteChange)="onRouteChange($event)"
  [application]="'ORD'"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment==='dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
  [menuItems]="menuItems"
/>

<rs-loader />

<router-outlet></router-outlet>

<rs-footer
  [cookiesKey]="'FOOTER.COOKIES'"
  [disclaimerKey]="'FOOTER.DISCLAIMER'"
  [needHelpKey]="'FOOTER.NEED_HELP'"
/>