import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { RegistrationFilters } from '@app/registrations/shared/models/registration-filters';
import { Keys, localStorageSync } from 'ngrx-store-localstorage';
import { produce } from 'immer';
import {
  rsLocalStorageMergeReducer
} from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';
import {
  updateActiveRegistrationFilters
} from '@app/registrations/overview/active/state/registrations-list-active.actions';

export const defaultActiveRegistrationFilters = {
  resultsPerPage: 10,
  page: 1,
  registrationStatus: []
};

export interface RegistrationsListActiveState {
  activeRegistrationFilters: RegistrationFilters;
}

export const initialRegistrationsListActiveState: RegistrationsListActiveState = {
  activeRegistrationFilters: defaultActiveRegistrationFilters
};

export const registrationsListActiveReducer = createReducer(
  initialRegistrationsListActiveState,
  on(updateActiveRegistrationFilters, (state, { activeRegistrationFilters }) =>
    produce(state, draft => {
      Object.assign(draft.activeRegistrationFilters, activeRegistrationFilters);
    })),
);

const persistentReducerKeys = ['registrationsListActive', 'activeRegistrationFilters'] as Keys;

export function localStorageSyncReducer(reducer: ActionReducer<RegistrationsListActiveState>): ActionReducer<RegistrationsListActiveState> {
  return localStorageSync({
    keys: persistentReducerKeys,
    storageKeySerializer: (key): string => 'ord_persistent_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const registrationsListActiveMetaReducers: Array<MetaReducer<RegistrationsListActiveState>> = [localStorageSyncReducer];
