import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { PackageDetails } from '@distribution-center/packages/shared/models/package-details';
import { GetPackagesQueryParams } from '@distribution-center/packages/shared/models/get-packages-query-params';
import { PackageBasic } from '@distribution-center/packages/shared/models/package-basic';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { CreatePackage } from '@distribution-center/packages/shared/models/create-package';
import { RsFormatPaginatedListRequestBody } from '@rs-shared-lib/models/rs-format-paginated-list-request-body';
import { RsUtils } from '@lib/rs-ngx';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  constructor(private readonly httpClient: HttpClient) {
  }

  public readonly getPackageDetails = (id: string): Observable<PackageDetails> => {
    return this.httpClient.get<PackageDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package/${id}`
    );
  };

  public readonly createPackage = (body: CreatePackage): Observable<PackageDetails> => {
    return this.httpClient.post<PackageDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package`,
      body
    );
  };

  public readonly getPackages = (queryParams: Partial<GetPackagesQueryParams>): Observable<RsFormatPaginatedListRequestBody<PackageBasic[]>> => {
    const params = RsUtils.trimEmptyHttpParams(queryParams);

    return this.httpClient
      .get<PackageDetails[]>(
        `${environment.apiGatewayUrl}/distribution-center/package`,
        { observe: 'response', params }
      ).pipe(
        rsFormatPaginatedListRequestBody()
      );
  };

  public readonly getAllActivePackages = (): Observable<RsFormatPaginatedListRequestBody<PackageBasic[]>> => {
    return this.getPackages({ active: 'ACTIVE', _pageSize: 9999 });
  };

  public readonly deletePackageDetails = (id: string): Observable<void> => {
    return this.httpClient.delete<void>(
      `${environment.apiGatewayUrl}/distribution-center/package/${id}`
    );
  };

  public readonly updatePackage = (packageId: string, body: CreatePackage): Observable<PackageDetails> => {
    return this.httpClient.put<PackageDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package/${packageId}`,
      body
    );
  };
}
