import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DefaultDeliveryInformation } from '@app/registrations/shared/models/registration';
import { OrderNotificationVersion } from '@app/administration/models/order-notification-version';
import { LeasingCompanyAdministration } from '@app/administration/models/leasing-company-administration';

@Injectable()
export class AdministrationService {

  constructor(
    private readonly http: HttpClient
  ) {
  }

  public getLeasingCompanyConfig(lcnumber: string): Observable<LeasingCompanyAdministration> {
    return this.http.get<LeasingCompanyAdministration>(
      `${environment.apiUrl}/leasing-company/${lcnumber}`
    );
  }

  public updateLeasingCompanyConfig(lcnumber: string, administrativeInfo: LeasingCompanyAdministration): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/leasing-company/${lcnumber}`, administrativeInfo);
  }

  public updateDefaultDeliveryInformation(deliveryInformation: DefaultDeliveryInformation['leasingCompanyDeliveryAddressesJson']): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/leasing-company/default-delivery-information/address`, deliveryInformation);
  }

  public orderNotificationResource(): Observable<OrderNotificationVersion[]> {
    return this.http.get<OrderNotificationVersion[]>(
      `${environment.apiUrl}/order-notification/versions`
    );
  }
}
