import { Injectable } from '@angular/core';
import {
  RegistrationOverviewService
} from '@app/registrations/shared/services/registration-overview/registration-overview.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadInsuranceBrokers,
  loadInsuranceBrokersFailure,
  loadInsuranceBrokersSuccess
} from '@app/core/state/registrations/registrations.actions';

@Injectable()
export class RegistrationsEffects {

  public loadInsuranceBrokers = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadInsuranceBrokers),
      mergeMap(() => this.registrationOverviewService.getInsuranceBrokers()
        .pipe(
          map(insuranceBrokers => loadInsuranceBrokersSuccess({ insuranceBrokers: insuranceBrokers })),
          catchError(async () => loadInsuranceBrokersFailure)
        ))
    )
  });

  constructor(
    private readonly registrationOverviewService: RegistrationOverviewService,
    private readonly actions$: Actions
  ) {
  }

}
